<template>
  <q-card style="width: 1000px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        预约信息
        <small class="q-ml-sm text-size-sm">
          <!-- {{ PdmMaApptEntity.PdmMaApptName }}({{
                            PdmMaApptEntity.PdmMaApptID
                        }}) -->
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm q-pr-sm">
        <div class="col-12">
          <q-card class="my-card">
            <div class="row  q-pa-sm q-gutter-x-sm">
              <q-input
                label="身份证号"
                dense
                outlined
                class="col-3"
                v-model="PdmMaApptEntity.ApptCertNo"
              />
              <q-input
                label="His用户ID"
                dense
                outlined
                readonly
                class="col-2"
                v-model="PdmMaApptEntity.HisPatientID"
              />
              <q-input
                label="患者名称"
                dense
                outlined
                readonly
                class="col-2"
                v-model="PdmMaApptEntity.ApptName"
              />
              <q-input
                label="联系电话"
                dense
                outlined
                readonly
                class="col-2"
                v-model="PdmMaApptEntity.ApptPhone"
              />

              <q-btn
                color="primary"
                @click="onGetMZ"
                :loading="PdmMaApptListLoading"
              >
                门诊查询
              </q-btn>
              <q-btn
                color="primary"
                @click="onGetZY"
                :loading="PdmMaApptListLoading"
              >
                住院查询
              </q-btn>
            </div>
          </q-card>
        </div>
        <div class="col-12 " v-if="DateInfo.length > 0">
          <q-card class="my-card">
            <div class="text-subtitle1 q-pl-sm">预约日期：</div>
            <q-separator />
            <div>
              <q-btn-toggle
                v-model="DateModel"
                spread
                class="my-custom-toggle"
                no-caps
                rounded
                unelevated
                toggle-color="primary"
                color="white"
                text-color="primary"
                :options="DateInfo"
                @update:model-value="onDateUpdate"
              />
            </div>
          </q-card>
        </div>
        <div class="col-12 " v-if="PackList.length > 0">
          <q-card class="my-card">
            <div class="text-subtitle1 q-pl-sm">预约套餐：</div>
            <q-separator />
            <div>
              <q-option-group
                v-model="PackModel"
                :options="PackList"
                color="primary"
                inline
                label
                @update:model-value="onPackUpdate"
              />
            </div>
          </q-card>
        </div>
        <div class="col-12 " v-if="DateTimeInfo">
          <q-card class="my-card">
            <div class="text-subtitle1 q-pl-sm">上午：</div>
            <q-separator />
            <div v-if="DateTimeInfo.ShangWuTime.length > 0">
              <q-option-group
                v-model="DateTimeModel"
                :options="DateTimeInfo.ShangWuTime"
                color="primary"
                inline
                label
                @update:model-value="onDateTimeUpdate1"
              />
            </div>
          </q-card>
        </div>
        <div class="col-12 " v-if="DateTimeInfo">
          <q-card class="my-card">
            <div class="text-subtitle1 q-pl-sm">下午：</div>
            <q-separator />
            <div v-if="DateTimeInfo.XiaWuTime.length > 0">
              <q-option-group
                v-model="DateTimeModel"
                :options="DateTimeInfo.XiaWuTime"
                color="primary"
                inline
                label
                @update:model-value="onDateTimeUpdate2"
              />
            </div>
          </q-card>
        </div>
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dlg from "@/config/dialog";
import * as MaSchd from "@/api/pdm/ma/maSchd";
import * as MaPack from "@/api/pdm/ma/maPack";
import * as MaSchdtime from "@/api/pdm/ma/maSchdtime";
import * as Patientinfo from "@/api/pdm/patientinfo";
import * as MaAppt from "@/api/pdm/ma/maAppt";

export default {
  components: {},
  props: {},
  computed: {
    ...mapState("PdmMaAppt", [
      "PdmMaApptShowDlg",
      "PdmMaApptEntity",
      "PdmMaApptList",
      "PdmMaApptListLoading",
    ]),
  },
  data() {
    return {
      DateInfo: [],
      DateModel: null,
      PackList: [],
      PackModel: null,
      DateTimeInfo: null,
      DateTimeModel: null,
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmMaAppt", ["actUpdatePdmMaAppt", "actGetPdmMaApptList"]),

    onLoadData() {
      MaSchd.GetPDM_MA_SchdData({
        HospID: this.$store.getters["appUser/hospID"],
      }).then((res) => {
        if (res.Code == 0) {
          this.DateInfo = res.Data;
          this.msg.success(res.Message);
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onDateUpdate(e) {
      this.DateModel = e;
      this.onGetMaPack();
    },
    onGetMaPack() {
      MaPack.GetIsOffPDM_MA_PackList({
        HospID: this.$store.getters["appUser/hospID"],
        PaiBan_DateStr: this.DateModel,
      }).then((res) => {
        if (res.Code == 0) {
          this.PackList = res.OtherData.data;
          this.msg.success(res.Message);
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onPackUpdate(e) {
      this.PackModel = e;
      this.onGetMaSchdtime();
    },
    onGetMaSchdtime() {
      MaSchdtime.GetSchdTimeData({
        HospID: this.$store.getters["appUser/hospID"],
        PaiBan_ID: this.PackModel,
      }).then((res) => {
        if (res.Code == 0) {
          this.DateTimeInfo = res.OtherData.data;
          this.msg.success(res.Message);
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onDateTimeUpdate1(e) {
      this.DateTimeModel = e;
    },
    onDateTimeUpdate2(e) {
      this.DateTimeModel = e;
    },
    onGetMZ() {
      var obj = {
        PATIENT_NUMBER: this.PdmMaApptEntity.ApptCertNo,
        PATIENT_TYPE: "02",
      };
      Patientinfo.GetPatnInfoEntity({
        HospID: this.$store.getters["appUser/hospID"],
        Info: obj,
      }).then((res) => {
        if (res.Code == 1) {
          var data = res.Data;
          this.PdmMaApptEntity.HisPatientID = data.PatnID;
          this.PdmMaApptEntity.ApptName = data.PatnName;
          this.PdmMaApptEntity.ApptPhone = data.Tel;
          this.msg.success(res.Message);
        } else if (res.Code == 0) {
          dlg.alert("未能查询到患者信息！");
        } else{
          dlg.alert(res.Message);
        }
      });
    },
    onGetZY() {
      Patientinfo.GetZYPatnInfoList({
        HospID: this.$store.getters["appUser/hospID"],
        CertNo:this.PdmMaApptEntity.ApptCertNo,
      }).then((res) => {
        if (res.Code == 1) {
          var data = res.Data;
          this.PdmMaApptEntity.HisPatientID = data[0].PatnID;
          this.PdmMaApptEntity.ApptName = data[0].PatnName;
          this.PdmMaApptEntity.ApptPhone = data[0].Tel;
          this.msg.success(res.Message);
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onOK() {
      this.PdmMaApptEntity.SchdID = this.PackModel;
      this.PdmMaApptEntity.PackID = this.PackList.find(
        (a) => a.value == this.PackModel
      ).PackID;
      this.PdmMaApptEntity.SchdTimeID = this.DateTimeModel;
      this.PdmMaApptEntity.ApptDate = this.DateModel;

      MaAppt.UpdatePDM_MA_ApptEntity2({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmMaApptEntity,
      })
        .then((res) => {
          if (res.Code == 0) {
            this.$emit("onLoadData");
            this.msg.success(res.Message);
          } else {
            dlg.alert(res.Message);
          }
        })
        .finally(() => {
          this.$store.commit("PdmMaAppt/setPdmMaApptShowDlg", false);
        });
    },
  },
};
</script>
