/*------------------------------------------------
// File Name:PDM_PatientInfo.js
// File Description:PDM_PatientInfo API方法js接口
// Author:zxl
// Create Time:2023/08/16 18:23:49
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _patientinfo: "/PDM_PatientInfo"
}

export default api


//-------------------创建对象-------------------
export function CreatePDM_PatientInfoEntity(parameter) {
    return axios({
        url: api._patientinfo + "/CreatePDM_PatientInfoEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取列表-------------------
export function GetPDM_PatientInfoList(parameter) {
    return axios({
        url: api._patientinfo + "/GetPDM_PatientInfoList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表---------------
export function GetPDM_PatientInfoListByPage(parameter) {
    return axios({
        url: api._patientinfo + "/GetPDM_PatientInfoListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取对象---------------
export function GetPDM_PatientInfoEntity(parameter) {
    return axios({
        url: api._patientinfo + "/GetPDM_PatientInfoEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存对象---------------
export function UpdatePDM_PatientInfoEntity(parameter) {
    return axios({
        url: api._patientinfo+ "/UpdatePDM_PatientInfoEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉对象---------------
export function DeletePDM_PatientInfoEntity(parameter) {
    return axios({
        url: api._patientinfo + "/DeletePDM_PatientInfoEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------HTTP获取HSM患者信息--门诊---------------
export function GetPatnInfoEntity(parameter) {
    return axios({
        url: api._patientinfo + "/GetPatnInfoEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------HTTP获取HSM患者信息--住院---------------
export function GetZYPatnInfoList(parameter) {
    return axios({
        url: api._patientinfo + "/GetZYPatnInfoList",
        method: 'post',
        data: parameter
    })
}